export const adapt_tokko_development = (response) => {
    response.construction_status = convertStatusIdToString(response.construction_status)
    return response
} 

export const convertStatusIdToString = statusId => {
    console.log(statusId)
    switch (statusId) {
      case 3:
        return "EN POZO"
      case 4:
        return "EN CONSTRUCCIÓN"
      case 6:
        return "TERMINADOS"
      default:
        return statusId
    }
  }